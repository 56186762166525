header {
    text-align: center;
    padding: 20px;
}

header h1 {
    font-size: 2em;
    color: #fff;
}

header h3 {
    font-size: 1.2em;
    color: #fff;
}

header img {
    width: 100%;
    height: auto;
}