.discord-button {
    margin-top: -7px;
    background-color: #5865F2;
    /* Couleur de fond Discord */
    color: white;
    /* Texte blanc */
    border: none;
    /* Pas de bordure */
    padding: 20px 20px;
    /* Padding pour augmenter la taille du bouton */
    border-radius: 5px;
    /* Bordures arrondies */
    font-size: 20px;
    /* Taille du texte */
    font-weight: bold;
    /* Texte en gras */
    cursor: pointer;
    /* Change le curseur en main quand survolé */
    text-transform: uppercase;
    /* Texte en majuscules */
    transition: background-color 0.3s;
    /* Transition pour effet au survol */
}

.discord-button:hover {
    background-color: #4e5d94;
    /* Changement de couleur au survol */
}

.cta {
    padding: 0 20px;
}