.image-card {
    overflow: hidden;
    text-align: center;
    margin: 10px;
}

.image-card-img {
    width: 80%;
    border-radius: 10px 10px 0 0;
}

.image-card-title {
    margin: 10px 0;
    font-size: 1.2em;
    color: #fff;
}

.image-card-description {
    font-size: 1em;
    color: #fff;
    padding: 0 15px;
}

.separation {
    height: 2px;
    background-color: #fff;
    margin: 20px 50px 40px 50px;
}