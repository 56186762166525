footer {
    margin-top: 40px;
    text-align: center;
    padding: 20px;
}

footer a {
    margin: 0 10px;
    color: #fff;
    /* ou la couleur de votre choix */
    font-size: 24px;
    /* ajustez la taille selon vos besoins */
}

footer a:hover {
    color: #555;
    /* couleur au survol */
}